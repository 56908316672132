import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import getImagePath from "helpers/get-image-path";

import { getCalculatedContainer } from "./responsiveHandle";

import { 
  basicSlide, basicSlideItem, basicSlideImg, basicSlideTitle,
  basicSlideTag,
} from "./style.mod.scss";

const BasicSlide = ({ data }) => {

  const { title, subtitle, slider } = data;

  if ((typeof window !== "undefined")) {
    var containerWidth = (window.innerWidth - 17);
  }
  
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    const { screenResolution } = getCalculatedContainer();

    setDimensions(
      ((containerWidth - screenResolution) / 2)
    );
  },[]);

  var settings = {
    slidesPerView: 1.5,
    spaceBetween: 35,
    pagination: { clickable: true },
    breakpoints: {
      1200: {
        pagination: { clickable: true },
        spaceBetween: 65,
        slidesPerView: 3.5,
      }
    }
  };

  return (
    <div style={{ marginLeft: dimensions + "px" }} className={basicSlide}>
      <span className={basicSlideTag}>{title}</span>
      <h2 className={basicSlideTitle}>{subtitle}</h2>
      <Swiper {...settings}>
        {
          slider.map((slide) => {
            const { slideImage, _key } = slide;
            return (
              <SwiperSlide key={_key} className={basicSlideItem}>
                <img src={getImagePath(slideImage)} alt={slideImage.alt} className={basicSlideImg} />
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>
  );
};

export default BasicSlide;
